import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from 'react-bootstrap'

const Topmessage = ({location}) => {

  const data = useStaticQuery(graphql`
    query {
      topmessage: file(relativePath: { eq: "iharasan-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      name: file(relativePath: { eq: "president_nameplate2.png" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
    }
  `)

  const Br = () => <br className="d-lg-block d-none" />

  return(
    <Layout location={location} title="トップメッセージ" >
      <SEO title="トップメッセージ" description="取締役会長、伊原栄⼀からのメッセージをお伝えいたします。"/>

      <div className="page-head">
				<Container expand="lg">
					<h2>トップメッセージ</h2>
					<p>取締役会長、伊原栄⼀からのメッセージをお伝えいたします。</p>
				</Container>
				<div className="bottom-border" />
			</div>

      <Container expand="lg">
        <h1 className="mb-5">オンリーワンのブランドをめざして。</h1>
        <p>
          グローバルワイズは、社名の由来でもある世界的な視野（GLOBAL）に⽴ち、知恵（WISE）を提供し、<Br />
          お客様のビジネスモデルをより⾶躍させ、また、収益構造そのものを創造するためのソリューションを提供し、<Br />
          “グローバルワイズと仕事をすると『何か』が⽣まれる”<Br />
          そんなオンリーワンのブランドをめざしています。<Br />
        </p>
        <Row className="mt-5">
          <Col lg={8}>
            <p>
              それは、IT（情報技術）にとどまらず、より有機的なサービスを提供することで、<Br />
              お客様のさらなる発展に寄与できるものと確信しております。<Br />
              <br/>
              そして、常にプロ意識を持って、仕事に取り組む集団であり、<Br />
              お客様と共に、お客様と関係する⽅々の成功の為、誠⼼誠意ベストを尽くすこと。<Br />
              <br/>
              最先端の技術やプロダクトだけではなく、<Br />
              これらのマインドをもった⼈財が、弊社の武器であり、宝であり、核となります。
            </p>
            <Img fluid={data.name.childImageSharp.fluid} className="my-5" alt="トップメッセージ"/>
          </Col>
          <Col lg={4}>
            <Img fluid={data.topmessage.childImageSharp.fluid} alt="トップメッセージ" className="mx-auto mx-lg-0"/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Topmessage